import { Application } from '../application';
import { OAuth2AuthorizationResponse, OAuth2TokenResponse } from '../oauth2';
import { StrategyLinkResource, StrategyURIOptions } from './interfaces';
import { Strategy } from './strategy';

export class GoogleOAuth2Strategy {
  static AUTHORIZE_URI = 'https://accounts.google.com/o/oauth2/v2/auth';

  static getAuthorizeURL(client_id: string, options?: StrategyURIOptions): URL {
    return Strategy.getAuthorizeURL(this.AUTHORIZE_URI, client_id, options);
  }

  static async authorize(
    application: Application['client_id'],
    client_id: string,
    response: OAuth2AuthorizationResponse,
    options?: { code_verifier?: string },
  ): Promise<OAuth2TokenResponse> {
    return Strategy.authorize(
      'google-oauth2',
      application,
      client_id,
      response,
      options,
    );
  }

  static async link(
    application: Application['client_id'],
    token: StrategyLinkResource,
    client_id: string,
    response: OAuth2AuthorizationResponse,
    options?: { code_verifier?: string },
  ): Promise<OAuth2TokenResponse> {
    return Strategy.link(
      'google-oauth2',
      application,
      token,
      client_id,
      response,
      options,
    );
  }
}
