import { Application } from '../application';
import { OAuth2AuthorizationResponse, OAuth2TokenResponse } from '../oauth2';
import { StrategyURIOptions } from './interfaces';
import { Strategy } from './strategy';

export class GitHubStrategy {
  static AUTHORIZE_URI = 'https://github.com/login/oauth/authorize';

  static getAuthorizeURL(client_id: string, options?: StrategyURIOptions): URL {
    return Strategy.getAuthorizeURL(this.AUTHORIZE_URI, client_id, options);
  }

  static async authorize(
    application: Application['client_id'],
    client_id: string,
    response: OAuth2AuthorizationResponse,
    options?: { code_verifier?: string },
  ): Promise<OAuth2TokenResponse> {
    return Strategy.authorize(
      'github',
      application,
      client_id,
      response,
      options,
    );
  }
}
