import { Application } from '../application';
import { HttpService } from '../http';
import { StrategyLinkResource } from './interfaces';

export class LocalDatabaseStrategy {
  static async authorize(
    application: Application['client_id'],
    connection: string,
    email: string,
    password: string,
  ): Promise<void> {
    await HttpService.post(
      '/strategy/local/login',
      { email, password },
      {
        params: {
          client_id: application,
          connection,
        },
      },
    );
  }

  static async resetPasswordRequest(
    application: Application['client_id'],
    connection: string,
    email: string,
  ): Promise<void> {
    await HttpService.post(
      '/strategy/local/reset-password/request',
      { email },
      {
        params: {
          client_id: application,
          connection,
        },
      },
    );
  }

  static async resetPassword(token: string, password: string): Promise<void> {
    await HttpService.post(`/strategy/local/reset-password/${token}`, {
      password,
    });
  }

  static async link(
    application: Application['client_id'],
    connection: string,
    token: StrategyLinkResource,
    body: {
      email: string;
      password: string;
      [key: string]: unknown;
    },
  ): Promise<void> {
    await HttpService.post('/strategy/local/link', body, {
      params: {
        client_id: application,
        connection,
        token: token.token,
        resource: token.resource,
      },
    });
  }

  static async signup(
    application: Application['client_id'],
    connection: string,
    email: string,
    password: string,
  ): Promise<void> {
    await HttpService.post(
      '/strategy/local/signup',
      { email, password },
      {
        params: {
          client_id: application,
          connection,
        },
      },
    );
  }
}
