import { Application } from '../application';
import { HttpService } from '../http';
import {
  OAuth2AuthorizationResponse,
  OAuth2TokenResponse,
  OAUTH2_SOCIAL_CALLBACK_URI,
} from '../oauth2';
import { StrategyLinkResource, StrategyURIOptions } from './interfaces';

export class Strategy {
  static getAuthorizeURL(
    url: string,
    client_id: string,
    options?: StrategyURIOptions,
  ): URL {
    const uri = new URL(url);
    uri.searchParams.append('client_id', client_id);
    uri.searchParams.append(
      'redirect_uri',
      options?.redirect_uri || OAUTH2_SOCIAL_CALLBACK_URI,
    );

    uri.searchParams.append('response_type', 'code');
    uri.searchParams.append('scope', options?.scope || '');

    if (options?.prompt) {
      uri.searchParams.append('prompt', options.prompt);
    }

    // Add code challenge
    if (options?.code_challenge) {
      uri.searchParams.append('code_challenge', options.code_challenge);

      if (options?.code_challenge_method) {
        uri.searchParams.append('code_challenge_method', 'S256');
      }
    }

    // Add state
    if (options?.state) {
      uri.searchParams.append('state', options.state);
    }

    return uri;
  }

  static async authorize(
    strategy: string,
    application: Application['client_id'],
    client_id: string,
    response: OAuth2AuthorizationResponse,
    options?: { code_verifier?: string },
  ): Promise<OAuth2TokenResponse> {
    const { data } = await HttpService.post(
      `/strategy/${strategy}/authenticate`,
      {
        grant_type: 'authorization_code',
        client_id,
        code: response.code,
        redirect_uri: OAUTH2_SOCIAL_CALLBACK_URI,
        code_verifier: options?.code_verifier,
      },
      {
        params: {
          client_id: application,
        },
      },
    );

    return data;
  }

  static async link(
    strategy: string,
    application: Application['client_id'],
    token: StrategyLinkResource,
    client_id: string,
    response: OAuth2AuthorizationResponse,
    options?: { code_verifier?: string },
  ): Promise<OAuth2TokenResponse> {
    const { data } = await HttpService.post(
      `/strategy/${strategy}/link`,
      {
        grant_type: 'authorization_code',
        client_id,
        code: response.code,
        redirect_uri: OAUTH2_SOCIAL_CALLBACK_URI,
        code_verifier: options?.code_verifier,
      },
      {
        params: {
          client_id: application,
          token: token.token,
          resource: token.resource,
        },
      },
    );

    return data;
  }
}
