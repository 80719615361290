import { Application } from '../application';
import { OAuth2AuthorizationResponse, OAuth2TokenResponse } from '../oauth2';
import { StrategyLinkResource, StrategyURIOptions } from './interfaces';
import { Strategy } from './strategy';

export class WindowsLiveStrategy {
  static AUTHORIZE_URI = (tenant?: string): string =>
    `https://login.microsoftonline.com/${
      tenant || 'common'
    }/oauth2/v2.0/authorize`;

  static getAuthorizeURL(
    client_id: string,
    tenant: string,
    options?: StrategyURIOptions,
  ): URL {
    return Strategy.getAuthorizeURL(
      this.AUTHORIZE_URI(tenant),
      client_id,
      options,
    );
  }

  static async authorize(
    application: Application['client_id'],
    client_id: string,
    response: OAuth2AuthorizationResponse,
    options?: { code_verifier?: string },
  ): Promise<OAuth2TokenResponse> {
    return Strategy.authorize(
      'windowslive',
      application,
      client_id,
      response,
      options,
    );
  }

  static async link(
    application: Application['client_id'],
    token: StrategyLinkResource,
    client_id: string,
    response: OAuth2AuthorizationResponse,
    options?: { code_verifier?: string },
  ): Promise<OAuth2TokenResponse> {
    return Strategy.link(
      'windowslive',
      application,
      token,
      client_id,
      response,
      options,
    );
  }
}
